/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

// var mySVGsToInject = document.querySelectorAll('img.svg-icon');
//     new SVGInjector(mySVGsToInject);
    

$(document).ready(function() {

 // Change images when hovering.
 $('.change-images').hover(
        
               function () {
                 $('.first-image').hide();
                 $('.second-image').show();
               }, 
        
               function () {
                  $('.second-image').hide();
                  $('.first-image').show();
               }
            );

});


$(document).ready(function() {


          $( ".first-image" ).click(
               function () {
                 $('.first-image').hide();
                 $('.second-image').show();

               }
            );

          $( ".second-image" ).click(
               function () {
                 $('.first-image').show();
                 $('.second-image').hide();

               }
            );
});


  // Hide navbar when scrolling
  $(window).scroll(function() {
    if ($(".navbar").offset().top > 1) {
        $(".navbar-fixed-top").addClass("top-nav-collapse");
    } else {
        $(".navbar-fixed-top").removeClass("top-nav-collapse");
    }
  });

$(document).scroll(function() {
  var y = $(this).scrollTop();
  if (y > 350) {
    $('.bottom-menu').fadeIn();
  } else {
    $('.bottom-menu').fadeOut();
  }
});

$(window).on("scroll", function() {
  var scrollHeight = $(document).height();
  var scrollPosition = $(window).height() + $(window).scrollTop();
  if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
      $('.bottom-menu').fadeOut();
  }
});



  // $(function() {
  //   $('#NavbarToggleButton').click(function() {
  //     $('#navbar-hamburger').toggleClass('hidden');
  //     $('#navbar-close').toggleClass('hidden');  
  //   });
  // });


  $(document).ready(function () {
        $(".navbar-toggle").on("click", function () {
            $(this).toggleClass("active");
            $(".navbar-fixed-top").removeClass("top-nav-collapse");
            $('.bottom-menu').fadeOut();
        });
    });
          

  // var menu = document.querySelector('.buy-item');
  // var menuPosition = menu.getBoundingClientRect();
  // var placeholder = document.createElement('div');
  // placeholder.style.width = menuPosition.width + 500 + 'px';
  // placeholder.style.height = menuPosition.height + 'px';
  // var isAdded = false;

  // window.addEventListener('scroll', function() {
  //     if (window.pageYOffset >= menuPosition.top && !isAdded) {
  //         menu.classList.add('sticky');
  //         menu.parentNode.insertBefore(placeholder, menu);
  //         isAdded = true;
  //     } else if (window.pageYOffset < menuPosition.top && isAdded) {
  //         menu.classList.remove('sticky');
  //         menu.parentNode.removeChild(placeholder);
  //         isAdded = false;
  //     }
  // });

  // var heightSlider = $('.navbar').height();
  // $('.header').css({ marginTop : heightSlider });

  // $(document).on('click','.navbar-collapse.in',function(e) {
  //         if( $(e.target).is('a') && $(e.target).attr('class') !== 'dropdown-toggle' ) {
  //             $(this).collapse('hide');
  //         }
  //     });

// var stickySidebar = $('.sidebar-nav').offset().top;

// $(window).scroll(function() {  
//     if ($(window).scrollTop() > stickySidebar) {
//         $('.sidebar-nav').addClass('affix');
//     }
//     else {
//         $('.sidebar-nav').removeClass('affix');
//     }  
// });

// Dropdown toggle
$(document).ready(function(){
  $('.dropdown-toggle').dropdown();
});

// Little advertising
console.log( "Looking for a web developer/designer/photographer for your next project? - Check www.nikirutanen.com" );

// Remove max width and width attributes from all images
 $('img').each(function(){
 $(this).removeAttr('sizes');
 $(this).removeAttr('width');
 $(this).removeAttr('height');
 });

if ($(window).width() > 992) {
  $(".navbar-fixed-top").autoHidingNavbar({ });
}
$( document ).ready( function( $ ) {
  // Get the main WC image as a variable
  var wcih_main_imgage = $( 'a.woocommerce-main-image img' ).attr( 'srcset' );
  // This is what will happen when you hover a product thumb
  $( '.thumbnails a img' ).click(
    // Swap out the main image with the thumb
    function() {        
    var photo_fullsize = $( this ).attr( 'srcset' );
      $( '.woocommerce-main-image img' ).attr( 'srcset', photo_fullsize );
    }
  );
});



// $('.woocommerce h1').each(function(){ 
//   var $p = $(this); 
//   $p.html($p.html().replace(/\s(.*?)\s/, '<strong>$1</strong>')); 
// }); 

// $('.woocommerce h1').each(function(){ 
//   var $p = $(this); 
//   $p.html($p.html().replace(/^(\w+)/, '<strong>$1</strong>')); 
// }); 

$(".woocommerce h1").each(function(){
  var html = $(this).html();
  var split = html.split(" ");
  if (split.length > 1) {
    $(this).html($(this).text().replace(/^([^\s]+)\s?/, '<strong>$1</strong> '));
  }
});


// $('.woocommerce h1').each(function () {
//     var html = $(this).html();
//     var split = html.split(" ");
//     if (split.length > 1) {
//         split[split.length - 1] = "<strong>" + split[split.length - 1] + "</strong>"
//         $(this).html(split.join(" "));
//     }
// });

// Näyttää ja piilottaa OSTA-menun
// $(window).on("scroll", function () {
//    if ($(this).scrollTop() > 800) {
//     $('.buy-now-after-menu').fadeIn();
//    } else {
//     $('.buy-now-after-menu').fadeOut();
//    }
// });

  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $(function() {
      $('li.item-scroll > a').bind('click', function(event) {
          var $anchor = $(this);
          $('html, body').stop().animate({
              scrollTop: $($anchor.attr('href')).offset().top
          }, 1500, 'easeInOutExpo');
          event.preventDefault();
      });
  });

$(function() {
      $('a.item-scroll').bind('click', function(event) {
          var $anchor = $(this);
          $('html, body').stop().animate({
              scrollTop: $($anchor.attr('href')).offset().top
          }, 1500, 'easeInOutExpo');
          event.preventDefault();
      });
});

    // Lightbox gallery
    $(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    }); 


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // Load Events
  $(document).ready(UTIL.loadEvents);



})(jQuery); // Fully reference jQuery after this point.

